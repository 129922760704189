import { useEffect, useRef } from "react";
import { menuItems } from "../menuItems";
import React from "react";
import MenuItems from "./MenuItems";
import axios from "axios";

const Navbar = () => {
  const data_type = [
    {
      Category_id: "",
      Category_title: "",
      Category_description: "",
      Category_filename: "",
      Category_parentId: "",
    },
  ];
  const category_data = useRef(data_type);
  function callAPI() {
    axios
      .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getCategoryMenuItems", {}, {})
      .then((response) => {
        category_data.current = response.data;
        const category = category_data.current;
        var menu = new Map();
        var parentNames = new Map();
        for (var i = 0; i < category.length; i++) {
          if (category[i].Category_parentId == 0) {
            var key = category[i].Category_id;
            if (!menu.has(key)) {
              menu.set(key, []);
              parentNames.set(key, category[i].Category_title);
            }
          } else {
            key = category[i].Category_parentId;
            if (!menu.has(key)) {
              menu.set(key, [category[i]]);
            } else {
              menu.get(key).push(category[i]);
            }
          }
        }
        var parentCategories = [];
        i = 0;
        for (let [key, value] of menu) {
          var categoryItem = new Map();
          categoryItem.title = parentNames.get(key);
          categoryItem.url = "/catalog";
          categoryItem.id = key;
          var subcategories = [];
          for (var j = 0; j < value.length; j++) {
            var subcategoryItem = new Map();
            subcategoryItem.title = value[j].Category_title;
            subcategoryItem.url = "/catalog";
            subcategoryItem.id = value[j].Category_parentId;
            subcategoryItem.index = j;
            subcategories[j] = subcategoryItem;
          }
          if (value.length > 0) {
            categoryItem.submenu = subcategories;
          }
          parentCategories[i] = categoryItem;
          i++;
        }
        menuItems[2].submenu = parentCategories;
      })
      .catch((error) => console.log("error in getting menu items" + error));
  }

  useEffect(() => {
    callAPI();
  }, []);

  return (
    <nav>
      <ul className="menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
