import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import ImportExport from "@material-ui/icons/ImportExport";
import Business from "@material-ui/icons/ThumbsUpDown";
import LocalShipping from "@material-ui/icons/LocalShipping";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ServicesSection() {
  const classes = useStyles();

  return (
    <div className={classes.section} style={{ padding: "70px 50px" }}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title}>Our Services</h2>
          <hr style={{ margin: "20px auto", opacity: "0.25" }} />
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Import & Export"
              icon={ImportExport}
              iconColor="costum"
              vertical
            />        
            <Link to={
                {
                  pathname:"/services",
                  index: 0
                }
              }
              style={{color:"inherit"}}
            >
              <Button simple color="info">LEARN MORE</Button>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Customs Brokerage Services"
              icon={Business}
              iconColor="costum"
              vertical
            />
            <Link to={
                {
                  pathname:"/services",
                  index: 1
                }
              }
              style={{color:"inherit"}}
            >
              <Button simple color="info">LEARN MORE</Button>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Freight Services"
              icon={LocalShipping}
              iconColor="costum"
              vertical
            />
            <Link to={
                {
                  pathname:"/services",
                  index: 2
                }
              }
              style={{color:"inherit"}}
            >
              <Button simple color="info">LEARN MORE</Button>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <InfoArea
              title="Outsourcing Marketing Services"
              icon={ArrowUpward}
              iconColor="costum"
              vertical
            />
            <Link to={
              {
                pathname:"/services",
                index: 3
              }
            }
            style={{color:"inherit"}}
            >
              <Button simple color="info">LEARN MORE</Button>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
