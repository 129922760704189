import React from "react";
import {useRef, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
/*icons*/
//import Plane from "@material-ui/icons/AirplanemodeActiveOutlined";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import myStyles from "assets/jss/material-kit-react/views/contactPage.js";
// core components
import Button from "components/CustomButtons/Button";
import emailjs from "@emailjs/browser";

const useStyles = makeStyles(styles);
const useMyStyle = makeStyles(myStyles);

export default function Getintouch() {
    const classes = useStyles();
    const myClasses = useMyStyle();
    const [email, setEmail]=useState("");
    const [emailError, setEmailError]=useState(false);
    const form=useRef();
    /*regex for email validation*/
    const isEmail = (mail) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mail);
    const handleSubmit = (e) => {
        e.preventDefault();
        setEmailError(false);
        if (isEmail(email)) {
            emailjs.sendForm('service_ab3hp56', 'template_zjpq73d', form.current, 'hXB9KzO8zvG89w4Wv')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
                //you can use sendForm function of '@emailjs/browser' in here for example.
                //EmailJS is a backend-as-a-service that gives you 
                //the tools to send emails to the users of your application
                //directly from your client or server-side code to fit your
                //business requirements. 
                //For more info visit: https://www.npmjs.com/package/@emailjs/browser
                // or https://www.emailjs.com/docs/sdk/installation/
            }
        else {
            setEmailError(true)
        };
    }
    return(
        <div style={{color: "#3c4858", textAlign: "center"}}>
            <div style={{marginTop: "16px", marginBottom: "16px"}}>
                <h2 className={classes.description} 
                    style={{
                      marginBottom: "0",
                      fontWeight: "300px",
                      color: "#040404",
                      textAlign: "center",
                      width: "100%",
                    }}>
                    Let&apos;s Get In Touch
                </h2>
                <form ref={form} onSubmit={handleSubmit} style={{marginTop: "10px"}}>
                    <p>
                        <input className={myClasses.formInput} type={'text'} placeholder={'Name'} required={true}
                            name={'from_name'} style={{width: "80%"}}/>
                    </p>
                    <p>
                        <input className={myClasses.formInput} type={'text'} placeholder={'Email'} required={true}
                            name={'reply_to'} onChange={event => setEmail(event.target.value)} style={{width: "80%"}}/>
                        {emailError && <label style={{color: "#757575"}}>Your email address is invalid</label>}
                    </p>
                    <p>
                        <input className={myClasses.formInput} type={'text'} placeholder={'Subject'} required={true}
                            name={'subject'} style={{width: "80%"}}/>
                    </p>
                    <p>
                        <textarea className={myClasses.formInput} type={'text'} placeholder={'Message'} required={true}
                            name={'message'} style={{width: "80%"}}/>
                    </p>
                    <p>
                        <Button type="submit" color="costum" disabled>
                            Send Message
                        </Button>
                    </p>
                </form>
            </div>
        </div>

    );
}