import React from "react";
import { useEffect, useState } from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import axios from "axios";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function NotificationCarouselSection() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const [carouselItems, setCarouselItems] = useState([]);
  const callCarouselAPI = async () => {
    await axios
      .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getCarouselItems", { }, {})
      .then((response) => {
        setCarouselItems(response.data);
      })
      .catch((error) => console.log("error in getting carousel items" + error));
  }

  useEffect(() => {
    callCarouselAPI();
  }, []);

  function renderCarouselItems (){
    return carouselItems.map((item, index) => {
      return(
        <div key={index}>
          <img src={process.env.REACT_APP_API_ADDRESS + "/carousels/" + item.Carousel_filename} alt={item.Carousel_alt} className="slick-image" />
          <div className="slick-caption">
            <h4 style={{fontWeight: "bolder"}}>{item.Carousel_caption}</h4>
          </div>
        </div>
      );
    })
  }

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                {renderCarouselItems()}
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
