import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
// @material-ui/icons
import ImportExport from "@material-ui/icons/ImportExport";
import Business from "@material-ui/icons/ThumbsUpDown";
import LocalShipping from "@material-ui/icons/LocalShipping";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import Navbar from "views/Header/Navbar.js";
import SocialLinks from "views/Header/SocialLinks.js";
import SnackbarContentView from "views/SnackbarContentView/SnackbarContentView";

import styles from "assets/jss/material-kit-react/views/servicePage.js";
import serv1 from "assets/img/service/serv1.jpg";
import serv2 from "assets/img/service/serv2.jpg";
import serv3 from "assets/img/service/serv3.jpg";
import serv4 from "assets/img/service/serv4.jpg";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

export default function ServicePage(props) {
  const classes = useStyles();
  const [active, setActive] = useState(props.location.index);
  const { ...rest } = props;

  return (
    <div>
      <head>
        <title>Gohar Tejarat Karimian Services</title>
        <meta name="description" content="Import & Export; Customs Brokerage Services; Freight Services; Outsourcing Marketing Services"></meta>
      </head>
      <SnackbarContentView/>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<Navbar />}
        rightLinks={<SocialLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
        style={{ paddingTop: "50px" }}
      />
      <Parallax
        filter
        image={require("assets/img/service/serv0.jpg").default}
      ></Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <NavPills
                setActive = {setActive}
                active = {active}
                color="costum"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 12, md: 2 },
                  contentGrid: { xs: 12, sm: 12, md: 10 },
                }}
                tabs={[
                  {
                    tabButton: "Import & Export",
                    tabIcon: ImportExport,
                    tabContent: (
                      <GridContainer className={classes.tab_content}>
                        <GridItem lg={7} md={7}>
                          <h4>
                            <span className={classes.service_title}>
                              Import & Export
                            </span>
                            &nbsp;for our own company as well as contract work for other
                            companies; based on market needs of origin or destination
                            country, products range from consumer goods to certain raw
                            material for industrial usage. 
                          </h4>
                        </GridItem>
                        <GridItem lg={5} md={5}>
                          <div className={classes.service_img_div}>
                            <img
                              src={serv1}
                              alt="Import & Export"
                              className={classes.service_img}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>                        
                    ),
                  },
                  {
                    tabButton: "Customs Brokerage Services",
                    tabIcon: Business,
                    tabContent: (
                      <GridContainer className={classes.tab_content}>
                        <GridItem lg={7} md={7}>
                          <h4>
                            <span className={classes.service_title}>
                              Customs Brokerage Services
                            </span>
                            &nbsp;perform all customs procedures for import or export
                            of goods and materials including communication with
                            government agencies on our customer&apos;s behalf and
                            ensure documents, duty and tax payments, and other
                            requirements related to the import or export of goods
                            comply with these regulations.
                          </h4>
                        </GridItem>
                        <GridItem lg={5} md={5}>
                          <div className={classes.service_img_div}>
                            <img
                              src={serv2}
                              alt="Customs Brokerage Services"
                              className={classes.service_img}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>                        
                    ),
                  },
                  {
                    tabButton: "Freight Services",
                    tabIcon: LocalShipping,
                    tabContent: (
                      <GridContainer className={classes.tab_content}>
                        <GridItem lg={7} md= {7}>
                          <h4>
                            <span className={classes.service_title}>
                              Freight Services
                            </span>
                            &nbsp;are facilitied for all modes of transport;
                            sea shipment, land shipment and air freight.
                          </h4>
                        </GridItem>
                        <GridItem lg={5} md={5}>
                          <div className={classes.service_img_div}>
                            <img
                              src={serv3}
                              alt="Freight Services"
                              className={classes.service_img}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>                        
                    ),
                  },
                  {
                    tabButton: "Outsourcing Marketing Services",
                    tabIcon: ArrowUpward,
                    tabContent: (
                      <GridContainer className={classes.tab_content}>
                        <GridItem lg={7} md={7}>
                          <h4>
                            <span className={classes.service_title}>
                              Outsourcing Marketing Services
                            </span>
                            &nbsp;including sourcing and purchasing to import
                            goods, sales & marketing of raw materials and final
                            products for manufacturers, wholesalers, suppliers
                            and users all over the world who have trust us to
                            handle and conduct marketing research and activities
                            in a professional, cost effective and time saving way
                            as their Exclusive Agent.
                          </h4>
                        </GridItem>
                        <GridItem lg={5} md={5}>
                          <div className={classes.service_img_div}>
                            <img
                              src={serv4}
                              alt="Outsourcing Marketing Services"
                              className={classes.service_img}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>                        
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
        <br />
      </div>
      <Footer />
    </div>
  );
}
