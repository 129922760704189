import { container, primaryColor } from "assets/jss/material-kit-react.js";

import tooltip from "assets/jss/material-kit-react/tooltipsStyle.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important",
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative",
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF",
    },
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    marginLeft: "30px",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px",
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px",
  },
  map: {
    position: "relative",
    marginTop: "30px",
    marginRight: "30px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    maxWidth: "509px",
    height: "300px",
    "@media (max-width: 576px)": {
      marginLeft: "30px",
      marginRight: "30px",
      borderRadius: "0px 0px 6px 6px",
    },
    "@media (min-width: 576px) and (max-width: 992px)": {
      height: "200px",
      margin: "auto",
    },
  },
  item: {
    display: "inline-block",
    "@media (max-width: 576px)": {
      textAlign: "center",
    },
  },
  description: {
    color: "#040404",
  },
};
export default footerStyle;
