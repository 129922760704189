import productStyle from "./landingPageSections/productStyle";

const EventsSectionStyle = {
  ...productStyle,
  tripleCards: {
    "@media (min-width: 992px)": {
      display: "flex",
    },
  },
  card: {
    "@media (min-width: 992px)": {
      marginBottom: "0 !important",
    },
    marginBottom: "8px",
  }
};

export default EventsSectionStyle;
