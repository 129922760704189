import * as React from 'react';
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import BasicCard from "components/Card/Card.js";
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import axios from "axios";

import styles from "assets/jss/material-kit-react/views/eventsSection.js";

const useStyles = makeStyles(styles);

function MediaCard({items}) {
    const history = useHistory();
    function handleClick(target, event) {
        history.push({
            pathname:target,
            state:{
                event:event
              }
          });
      }

    function renderMediaCard(){
    return items.map((item, index) => {
        if(index == 0)
        {const {Event_id, Event_title, Event_date, Event_description, Event_filename} = item;
        var shortDescription = "" + Event_description;
        shortDescription = shortDescription.slice(0, 120);
            return (
                <Card sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{width: "60%"}}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h5" style={{color: "#3C4858"}}>
                                {Event_title}
                            </Typography>
                            <Typography variant="subtitle1" component="div" style={{color: "#3C4858"}}>
                                {Event_date}
                            </Typography>
                            <Typography variant="subtitle2" component="div" style={{color: "#3C4858"}}>
                                {shortDescription}
                            </Typography>
                        </CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                            <Button 
                                color="costum"
                                style={{marginLeft: "5px"}}
                                onClick={() => handleClick("/blog/"+Event_id, item)}
                            >
                                Find out more
                            </Button> 
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{margin: "auto", paddingTop: "10px", paddingBottom: "10px"}}>
                        <CardMedia
                            component="img"
                            sx={{ width: 150, height: 150 }}
                            image={process.env.REACT_APP_API_ADDRESS + "/events/" + Event_filename}
                            alt={Event_title}
                            style={{marginBottom: "5px"}}
                        />
                    </Box>
                </Card>
                );
            }
    })
      }

    return(
        <>
            {renderMediaCard()}
        </>
    );

}

function CardSection({items}){
    const classes = useStyles();
    const history = useHistory();
    function handleClick(target, event) {
        history.push({
            pathname:target,
            state:{
                event:event
              }
          });
    }
    

    function renderCard(){
        return items.map((item, index) => {
            if(index != 0)
            {const {Event_id, Event_title, Event_date, Event_description} = item;
            var shortDescription = "" + Event_description;
            shortDescription = shortDescription.slice(0, 120);
                return (
                    <GridItem xs={12} sm={12} md={6} lg={4} sx={{ flex: 1, display: 'flex', flexDirection: 'column'}} className={classes.card}>
                    <BasicCard style={{justifyContent: "center", height: '100%' }}>
                        <CardHeader style={{textAlign: "center", marginTop: "5px", color: "#3C4858", fontWeight: "bolder"}}>
                            <h3>{Event_title}</h3>
                            <h5>{Event_date}</h5>
                        </CardHeader>
                        <CardBody>
                            <p style={{textAlign: "justify", color: "rgb(4, 4, 4)", whiteSpace: "pre-wrap"}}>
                                {shortDescription}
                            </p>
                        </CardBody>
                        <CardFooter style={{justifyContent: "center"}}>
                            <Button
                                size="small"
                                color="costum"
                                onClick={() => handleClick("/blog/"+Event_id, item)}
                            >
                                Find out More
                            </Button>
                        </CardFooter>
                    </BasicCard>
                </GridItem>
                );}
            })
          }
    
        return(
            <>
                <GridContainer className={classes.tripleCards}>
                    {renderCard()}
                </GridContainer>
            </>
        );

}

export default function EventsSection(){
    const classes = useStyles();  
    const [eventItems, setEventItems] = useState([]);

    const callTopEventAPI = async () => {
        await axios
          .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getTopEventItems", {}, {})
          .then((response) => {
            setEventItems(response.data);
          })
          .catch((error) => console.log("error in getting product" + error));
      }

      useEffect(() => {
        callTopEventAPI();
        
      }, []);  
    return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <h2 className={classes.title} style={{textAlign: "center"}}>Events</h2>
                    <hr style={{ margin: "20px auto", opacity: "0.25" }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {eventItems.length>0? <MediaCard items={eventItems}/>: null}
                </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{marginTop: "40px"}}>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    {eventItems.length > 0? <CardSection items={eventItems}/>: null}
                </GridItem>
            </GridContainer>
        </div>
    );
}