import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
//core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

import Navbar from "views/Header/Navbar.js";
import SocialLinks from "views/Header/SocialLinks.js";
import SnackbarContentView from "views/SnackbarContentView/SnackbarContentView.js";
//Sections for this page
import ProductCollectionSection from "./Sections/ProductCollectionSection.js";
import NewsSection from "./Sections/NewsSection.js";
import ServicesSection from "./Sections/ServicesSection";
import EventsSection from "./Sections/EventsSection.js";
import RDSection from "./Sections/RDSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
// const useMyStyles = makeStyles(myStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function HomePage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <head>
        <title>Products</title>
        <meta name="description" content="Foods and Beverages, Agricultural Machinery, Crops"></meta>
      </head>
      <SnackbarContentView />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<Navbar />}
        rightLinks={<SocialLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
        style={{ paddingTop: "50px" }}
      />
      {/* require("assets/img/gohar.gif").default */}
      <Parallax filter image={process.env.REACT_APP_API_ADDRESS + "/settings/homepage.jpg"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{textAlign: "center"}}>
                <h2 className={classes.title}>
                  Your trust is our biggest asset; we won&apos;t let it go to
                  waste
                </h2>
                <br />
                <br />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductCollectionSection />
          <ServicesSection />
          <EventsSection />
          <NewsSection />
          <RDSection />     
        </div>
        <br />
      </div>
      <Footer />
    </div>
  );
}
