import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const productSubFeaturedStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  ...imagesStyle,
};

export default productSubFeaturedStyle;
