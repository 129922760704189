import React from "react";
import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import Navbar from "views/Header/Navbar.js";
import SocialLinks from "views/Header/SocialLinks.js";
import SnackbarContentView from "views/SnackbarContentView/SnackbarContentView.js";

import axios from "axios";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function BlogPage(props) {
    var event_id = props.match.params.id;
    const classes = useStyles();
    const { ...rest } = props;
    const [blog, setBlog] = useState([{}]);
    const [image, setImage] = useState();
    const callAPI = async (id) => {
      await axios
        .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getEventDetail", { id }, {})
        .then((response) => {
          setBlog(response.data);
          setImage(props.location.state.event.Event_filename);
        })
        .catch((error) => console.log("error in getting product detail" + error));
    }
  
    useEffect(() => {
      callAPI(event_id);
    }, [event_id]); 
    
    return (
      <div>
        <head>
          <title>Blog</title>
          <meta name="description" content={blog[0].Event_title}></meta>
        </head>
        <SnackbarContentView />
        <Header
          color="transparent"
          routes={dashboardRoutes}
          leftLinks={<Navbar />}
          rightLinks={<SocialLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
          style={{ paddingTop: "50px" }}
        />
        <Parallax small filter image={process.env.REACT_APP_API_ADDRESS + "/events/" + image} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: "80px" }}>
                  <div className={classes.profile}>
                    <div className={classes.name}>
                      <h3 className={classes.title}>{blog[0].Event_title}</h3>
                      <h5 style={{color: "#3C4858"}}>{blog[0].Event_date}</h5>
                      <hr style={{ margin: "20px auto", opacity: "0.25" }} />
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.description}>
                <p>
                  {blog[0].Event_description}
                </p>
              </div>
            </div>
          </div>
          <br />
        </div>
        <Footer />
      </div>
    );
  }
  