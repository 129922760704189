import React from "react";
import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import Navbar from "views/Header/Navbar.js";
import SocialLinks from "views/Header/SocialLinks.js";
import SnackbarContentView from "views/SnackbarContentView/SnackbarContentView.js";
// Sections for this page
import ProductSubFeaturedSection from "./Sections/ProductSubFeaturedSection.js";;

import styles from "assets/jss/material-kit-react/views/productDetailStyle";

import axios from "axios";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function ProductDetailPage(props) {
  var product_id = props.match.params.id;
  const classes = useStyles();
  const { ...rest } = props;
  const [product, setProduct] = useState([{}]);
  const [image, setImage] = useState();
  const callAPI = async (id) => {
    await axios
      .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getProductDetail", { id }, {})
      .then((response) => {
        setProduct(response.data);
        setImage(props.location.state.product.Product_filename);
      })
      .catch((error) => console.log("error in getting product detail" + error));
  }

  useEffect(() => {
    callAPI(product_id);
  }, [product_id]);

  return (
    <div>
      <SnackbarContentView />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<Navbar />}
        rightLinks={<SocialLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
        style={{ paddingTop: "50px" }}
      />
      <Parallax small filter image={process.env.REACT_APP_API_ADDRESS + "/products/" + image} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <div className={classes.section}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <ProductSubFeaturedSection product={product} masterProduct={props.location.state.product}/>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <br />
      </div>
      <Footer />
    </div>
  );
}
