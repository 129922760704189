import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";

import Navbar from "views/Header/Navbar.js";
import SocialLinks from "views/Header/SocialLinks.js";
import SnackbarContentView from "views/SnackbarContentView/SnackbarContentView";
import NewsCards from "views/NewsPage/Sections/NewsCards.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

export default function NewsPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <head>
        <title>Products</title>
        <meta name="description" content="Trading news around Asia and Middle East"></meta>
      </head>
      <SnackbarContentView />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<Navbar />}
        rightLinks={<SocialLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
        style={{ paddingTop: "50px" }}
      />
      <Parallax
        filter
        image={require("assets/img/news/news3.jpg").default}
      >
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <NewsCards mode="all"/>
        </div>
        <br />
      </div>
      <Footer />
    </div>
  );
}
