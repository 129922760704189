/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem, Tooltip, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Map from "components/Map/Map.js";
import Getintouch from "components/Footer/Sections/Getintouch";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <div>
      <footer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
              <List className={classes.list}>
                <ListItem className={classes.item}>
                  <h2 className={classes.description}>Contact Info</h2>
                  <h4 className={classes.description}>+98(0)9919003830</h4>
                  <h4 className={classes.description}>+98(0)4433653291</h4>
                  <h4 className={classes.description}>
                    info@gohartrade.com
                  </h4>
                  <h4 className={classes.description}>
                    No. 12, 6th floor, Roma Building, Edalat Ave. 
                  </h4>
                  <h4 className={classes.description}>Urmia-Iran</h4>
                  <h4 className={classes.description}>Sales & Marketing Manager: +98(0)9125798879</h4>
                </ListItem>
              </List>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} style={{paddingTop: "8px"}}>
            <div>
              <Getintouch />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} style={{paddingTop: "20px", marginTop: "20px"}}>
            <div className={classes.map}>
              <Map />
            </div>
          </GridItem>
        </GridContainer>
      </footer>
      <div style={{ textAlign: "left" }}>
        &copy; gohartrade.com
      </div>
    </div>

  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
