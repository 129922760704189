import React , { useEffect, useState }from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search.js";
import Close from "@material-ui/icons/Close.js";
import { List, ListItem } from "@material-ui/core";

import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/customModalStyle.js";
import '../../assets/css/search.css';

import axios from "axios";

const useStyles = makeStyles(styles);

const CloseInput = ({setClose, searchVal, setSearchVal}) =>{
  const classes = useStyles();
  const [products, setProducts] = useState();
  const history = useHistory();
  const handleClick = () => {
    setClose(false);
    setSearchVal("");
  }
  const callAPI = async () => {
    await axios
      .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getAllProducts", {}, {})
      .then((response) => {
        const filterBySearch = response.data.filter((item) => {
          if (item.Product_title.toLowerCase()
              .includes(searchVal.toLowerCase())) { return item; }
        })
        setProducts(filterBySearch);
      })
      .catch((error) => console.log("error in getting all products" + error));
  }
  function route(target, product) {
    history.push({
      pathname:target,
      state:{
        product:product
      }
    });
  }

  useEffect (() => {
    callAPI();
  }, [searchVal])

  return (
    <div className="searchInput">
      <CustomInput
        inputRootCustomClasses={classes.inputRootCustomClasses}
        formControlProps={{
          className: classes.formControl,
        }}
        inputProps={{
          placeholder: "Search",
          inputProps: {
            "aria-label": "Search",
            className: classes.searchInput,
            onChange: (e) => setSearchVal(e.target.value)
          },
        }}
      />
      <Button justIcon color="transparent" className="searchBtn"
        onClick={handleClick}
      >
        <Close  className={classes.icon}/>
      </Button>
      {searchVal !== ""?
      <div className="searchDropdown">
        <List>
        {products.length > 0?
          products.map((product, index) => {
            return (
              <ListItem  key={index}>
                <a
                  className="searchRes"
                  onClick={() => route("/product/"+product.Product_id, product)}
                >
                  <img className="searchImg"
                    src={process.env.REACT_APP_API_ADDRESS + "/products/" + product.Product_filename}
                  />
                  {product.Product_title}
                </a>
              </ListItem>
            )
          }):
          <ListItem>
            <p>No results found</p>
          </ListItem>
      }
        </List>
      </div>: null}
    </div>
  );
}  

const Searchbar= ({isOpen, setOpen, searchVal, setSearchVal}) => {
  const classes = useStyles();
 
  return (
      <div>
        <Button justIcon color="transparent" className="searchBtn"
          onClick={() => {setOpen(true);}}
        >
            <Search className={classes.icons} />
        </Button>
        {isOpen && <CloseInput 
          setClose={setOpen} searchVal={searchVal} setSearchVal={setSearchVal}
        />}
      </div>
  );
}

export default Searchbar;