import React from "react";
import { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import axios from "axios";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

const useStyles = makeStyles(styles);

export default function NewsCards({ mode }) {
  const classes = useStyles();
  const imageClasses = classNames(classes.imgRaised, classes.imgFluid);
  const [newsItems, setNewsItems] = useState([]);

  const callNewsAPI = async () => {
    await axios
      .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getNewsItems", {}, {})
      .then((response) => {
        setNewsItems(response.data);
      })
      .catch((error) => console.log("error in getting news" + error));
  }
  const callTopNewsAPI = async () => {
    await axios
      .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getTopNewsItems", {}, {})
      .then((response) => {
        setNewsItems(response.data);
      })
      .catch((error) => console.log("error in getting news" + error));
  }

  useEffect(() => {
    {mode=="top"? (callTopNewsAPI()):(callNewsAPI())}
    
  }, []);

  function renderNewsCard(){
    return newsItems.map((item, index) => {
      const {News_content, News_title, News_link, News_source, News_time} = item;
      var shortTitle = "" + News_title;
      shortTitle = shortTitle.slice(0, 20);
      var shortDescription = "" + News_content;
      shortDescription = shortDescription.slice(0, 100);
      return (
        <GridItem xs={12} sm={12} md={6} lg={4} key={index} style={{marginTop: "8px"}}>
          <Card style={{height: '100%'}}>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={classes.itemGrid}
              style={{ maxWidth: "90%" }}
            >
              <img
                src={process.env.REACT_APP_API_ADDRESS + "/news/" + item.News_filename}
                alt="..."
                className={imageClasses}
                style={{ marginTop: "20px" }}
              />
            </GridItem>
            <h4
              className={classes.cardTitle}
              style={{
                textAlign: "left",
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
            >
              {shortTitle}
              <br />
            </h4>
            <CardBody>
              <p
                className={classes.description}
                style={{ textAlign: "justify", color: "#060606" }}
              >
                {shortDescription}
              </p>
              <a
                style={{ display: "flex", color: "#00acc1" }}
                href={News_link}
                target="_blank"
                rel="noreferrer"
              >
                Countinue Reading
              </a>
            </CardBody>
            <CardFooter className={classes.justifyCenter}>
              <p style={{ color: "#7a7a7a"}}>{News_time + " by " + News_source}</p>
            </CardFooter>
          </Card>
        </GridItem>
      );
    })
  }

  return (
    <div className={classes.section} style={{ padding: "70px 50px" }}>
      <h2 className={classes.title}>News</h2>
      <hr style={{ margin: "20px auto", opacity: "0.25" }} />
      <div>
        <GridContainer style={{ justifyContent: "Center" }}>
          {renderNewsCard()}
        </GridContainer>
      </div>
    </div>
  );
}
