import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { List, ListItem, Tooltip, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Searchbar from "views/Searchbar/Searchbar.js";

const useStyles = makeStyles(styles);

const SocialLinks = () => {
  const classes = useStyles();
  const footerClasses = classNames({
    [classes.footer]: true,
  });
  const [isOpen, setOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const scrollToFooter = () => {
    window.scrollTo({left:0, top:document.body.scrollHeight, behavior:'smooth'})
  };
  return (
    <div className={footerClasses}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.right}>
              <div style={{ textAlign: "left" }}>
                <List>
                  <ListItem className={classes.inlineBlock}>
                    <Searchbar isOpen={isOpen} setOpen={setOpen} searchVal={searchVal} setSearchVal={setSearchVal}/>
                  </ListItem>
                  {!isOpen ? 
                  <div style={{ display: "inline-flex" }}>
                    <ListItem className={classes.inlineBlock}>
                      <Tooltip
                        id="linkedin-tooltip"
                        title="Follow us on Linkedin"
                        placement={window.innerWidth > 959 ? "bottom" : "left"}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          href="https://www.linkedin.com/in/gohar-tejarat-karimiyan-982718282/"
                          target="_blank"
                          color="transparent"
                          style={{ color: "#d3b574", minWidth: "50px" }}
                          className={classes.navLink}
                        >
                          <i
                            className={classes.socialIcons + " fab fa-linkedin"}
                          />
                        </Button>
                      </Tooltip>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Tooltip
                        id="instagram-tooltip"
                        title="Follow us on Instagram"
                        placement={window.innerWidth > 959 ? "bottom" : "left"}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="transparent"
                          style={{ color: "#d3b574", minWidth: "50px" }}
                          href="https://www.instagram.com/gohar_trade/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                          target="_blank"
                          className={classes.navLink}
                        >
                          <i
                            className={classes.socialIcons + " fab fa-instagram"}
                          />
                        </Button>
                      </Tooltip>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Tooltip
                        id="whatsapp-tooltip"
                        title="Contact us on Whatsapp"
                        placement={window.innerWidth > 959 ? "bottom" : "left"}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="transparent"
                          style={{ color: "#d3b574", minWidth: "50px" }}
                          //href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                          target="_blank"
                          className={classes.navLink}
                        >
                          <i
                            className={classes.socialIcons + " fab fa-whatsapp"}
                          />
                        </Button>
                      </Tooltip>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Tooltip
                        id="email-tooltip"
                        title="Contact us on info@gohartrade.com"
                        placement={window.innerWidth > 959 ? "bottom" : "left"}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="transparent"
                          style={{ color: "#d3b574", minWidth: "50px" }}
                          //href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                          target="_blank"
                          className={classes.navLink}
                          onClick={scrollToFooter}
                        >
                          <i
                            className={classes.socialIcons + " fa fa-envelope"}
                          />
                        </Button>
                      </Tooltip>
                    </ListItem>
                  </div>
                  : isOpen && window.innerWidth > 800? 
                  <div style={{ display: "inline-flex" }}>
                    <ListItem className={classes.inlineBlock}>
                      <Tooltip
                        id="linkedin-tooltip"
                        title="Follow us on Linkedin"
                        placement={window.innerWidth > 959 ? "bottom" : "left"}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          href="https://www.linkedin.com/in/gohar-tejarat-karimiyan-982718282/"
                          target="_blank"
                          color="transparent"
                          style={{ color: "#d3b574", minWidth: "50px" }}
                          className={classes.navLink}
                        >
                          <i
                            className={classes.socialIcons + " fab fa-linkedin"}
                          />
                        </Button>
                      </Tooltip>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Tooltip
                        id="instagram-tooltip"
                        title="Follow us on Instagram"
                        placement={window.innerWidth > 959 ? "bottom" : "left"}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="transparent"
                          style={{ color: "#d3b574", minWidth: "50px" }}
                          href="https://www.instagram.com/gohar_trade/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                          target="_blank"
                          className={classes.navLink}
                        >
                          <i
                            className={classes.socialIcons + " fab fa-instagram"}
                          />
                        </Button>
                      </Tooltip>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Tooltip
                        id="whatsapp-tooltip"
                        title="Contact us on Whatsapp"
                        placement={window.innerWidth > 959 ? "bottom" : "left"}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="transparent"
                          style={{ color: "#d3b574", minWidth: "50px" }}
                          //href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                          target="_blank"
                          className={classes.navLink}
                        >
                          <i
                            className={classes.socialIcons + " fab fa-whatsapp"}
                          />
                        </Button>
                      </Tooltip>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Tooltip
                        id="email-tooltip"
                        title="Contact us on info@gohartrade.com"
                        placement={window.innerWidth > 959 ? "bottom" : "left"}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="transparent"
                          style={{ color: "#d3b574", minWidth: "50px" }}
                          className={classes.navLink}
                        >
                            <i
                              className={classes.socialIcons + " fa fa-envelope"}>
                            </i>
                        </Button>
                      </Tooltip>
                    </ListItem>
                  </div>
                  :  null                                
                  }
                </List>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default SocialLinks;
