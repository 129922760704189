import landingPageStyle from "./landingPage";

const servicePageStyle = {
  ...landingPageStyle,
  tab_content: {
    textAlign: "justify",
    color: "#060606",
  },

  service_title: {
    color: "#d3b574",
    fontWeight: "bolder",
  },

  service_img_div: {
    padding: "20px",
    borderRadius: "10px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
  },

  service_img: {
    width: "100%",
    height: "470px",
    borderRadius: "10px",
    "&:focus,&:hover": {
      boxShadow:
        "0 4px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    },
  },
};

export default servicePageStyle;
