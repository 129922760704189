import React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";

import axios from "axios";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import NotificationCarouselSection from "./NotificationCarouselSection";

const useStyles = makeStyles(styles);

export default function ProductCollectionSection() {
  const classes = useStyles();
  const history = useHistory();
  function handleClick(target) {
    history.push(target);
  }
  const [categoryItems, setCategoryItems] = useState([]);
  const callCategoryAPI = async () => {
    await axios
      .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getCategoryItems", {}, {})
      .then((response) => {
        setCategoryItems(response.data);
      })
      .catch((error) => console.log("error in getting category items" + error));
  }

  useEffect(() => {
    callCategoryAPI();
  }, []);

  function renderCategoryCard(){
    return categoryItems.map((item, index) => {
      return (
        <GridItem xs={12} sm={12} md={6} lg={4} key={index}>
          <Card style={{ boxShadow: "none", justifyContent: "center" }}>
            <img 
              src={process.env.REACT_APP_API_ADDRESS + "/categories/" + item.Category_filename} alt="..."
              style={{
                borderRadius: "inherit", 
                width: "100%",
                height: "250px",
                maxWidth: "100%",
                maxHeight: "250px",
                objectFit: "cover"}}
            />
            <div
              style={{
                position: "absolute",
                padding: "1.25rem",
                color: "white",
                fontWeight: "bolder",
                width: "100%",
              }}
            >
              <p style={{ textAlign: "center", fontWeight: "bolder" }}> {item.Category_title} </p>
              <Button
                size="small"
                color="costum"
                onClick={() => handleClick("/catalog/"+item.Category_id)}
              >
                Order NOW
              </Button>
            </div>
          </Card>
        </GridItem>
      );
    })
  }
  return (
    <div className={classes.section} style={{ padding: "70px 50px" }}>
      <h2 className={classes.title}>Product</h2>
      <hr style={{ margin: "20px auto", opacity: "0.25" }} />
      <NotificationCarouselSection />
      <div>
        <GridContainer justify="center">
          {renderCategoryCard()}
        </GridContainer>
      </div>
    </div>
  );
}
