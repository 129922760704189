import React from "react";
import Thumbnail from "./Thumbnail";

export default function ThumbnailGrid(props) {
  function renderThumbnail () {
    return props.product.map((thumbnail, index) => {
      return(
        <Thumbnail handleClick={props.handleClick} image={thumbnail.Thumbnail_filename} key={index} />
      );
    })
  }
  
  return (
    <div
      style={{
        maxHeight: "35%",
        height: "35%",
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        objectFit: "contain",
      }}
    >
      <Thumbnail handleClick={props.handleClick} image={props.masterProduct.Product_filename} />
      {renderThumbnail()}
    </div>
  );
}
