import React from "react";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// @material-ui/icons
import Calendar from "@material-ui/icons/CalendarToday.js";
import Person from "@material-ui/icons/Person.js";

import logo from "assets/img/GTK.png";

const SnackbarContentView = () => {
    function getCurrentDate(separator = " ") {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${year}${separator}${
          month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`;
    }

    return (
        <SnackbarContent
            message={
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "50%", textAlign: "left" }}>
                    <img src={logo} style={{ height: "40px" }}/>
                    <span style=
                        {{ color: "#3c4858",
                           display: "block",
                           marginLeft: "-20px",
                           fontWeight: "600" 
                        }}>
                            Gohar Tejarat Karimian
                    </span>
                </div>
                <div style={{ width: "50%", textAlign: "right", color: "#3c4858"  }}>
                    <Calendar />
                    <span style={{ verticalAlign: "super", marginLeft: "5px", marginRight: "5px" }}>{getCurrentDate()}</span>
                    <Person />
                    <span style={{ verticalAlign: "super", marginLeft: "5px" }}>10,105</span>
                </div>
            </div>
            }
            style={{ backgroundColor: "white" }}
      />
    );
}

export default SnackbarContentView;