import { title } from "assets/jss/material-kit-react.js";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.js";
import RDSectionStyle from "assets/jss/material-kit-react/views/RDSection.js";

const downloadPageStyle = {
    ...landingPageStyle,
    ...RDSectionStyle,
    title: {
        ...title,
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
      },
    tab_content: {
        color: "#060606",
    },
    navWrapper: {
        margin: "20px auto 50px auto",
    },
};

export default downloadPageStyle;