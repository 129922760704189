import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/productSubFeaturedStyle.js";

const useStyles = makeStyles(styles);

export default function Thumbnail(props) {
  const classes = useStyles();
  
  return (
    <div style={{ height: "100%", width: "25%", padding: "3px" }}>
      <img
        src={process.env.REACT_APP_API_ADDRESS + "/products/" + props.image}
        alt="..."
        className={
          classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid
        }
        style={{ width: "120px", height: "100px" }}
        onClick={() => props.handleClick(props.image)}
      />
    </div>
  );
}
