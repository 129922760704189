import React from "react";
import { useHistory} from "react-router-dom";
// core components
import Button from "components/CustomButtons/Button";

import NewsCards from "views/NewsPage/Sections/NewsCards";

export default function NewsSection() {
  const history = useHistory();
  function handleClick(target) {
    history.push(target);
  }
  return (
    <>      
      <NewsCards mode="top"/>
      <Button 
        color="costum"
        onClick={() => handleClick("/news")}
        style={{ display: "block", margin: "auto" }}
      >
        VIEW ALL
      </Button>
    </>
    );
}
