import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem } from "@material-ui/core";
// @material-ui/icons
import Contact from "@material-ui/icons/ContactSupport";
import Person from "@material-ui/icons/Person";
import Phone from "@material-ui/icons/PhoneInTalk";
import Email from "@material-ui/icons/Email";
import Location from "@material-ui/icons/LocationCity";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import Navbar from "views/Header/Navbar.js";
import SocialLinks from "views/Header/SocialLinks.js";
import SnackbarContentView from "views/SnackbarContentView/SnackbarContentView.js";
import Getintouch from "./Sections/Getintouch";

import styles from "assets/jss/material-kit-react/views/contactPage.js";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

export default function ContactPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <head>
        <title>Contact Gohar Tejarat Karimian</title>
        <meta name="description" content="Phone: +98(0)9919003830, +98(0)4433653291; Email: info@gohartrade.com"></meta>
      </head>
      <SnackbarContentView />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<Navbar />}
        rightLinks={<SocialLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
        style={{ paddingTop: "50px" }}
      />
      <Parallax
        filter
        image={require("assets/img/contact/contact2.jpg").default}
      >
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h2
                  className={classNames(classes.title, classes.title_contact)}
                  style={{
                    marginBottom: "0",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Contact className={classes.icon}></Contact>
                  Contact <span style={{ color: "#d3b574" }}>Us</span>
                </h2>
                <hr className={classes.hr_contact} />
              </GridItem>
            </GridContainer>
          </div>
          <div>
            <GridContainer justify="center">
              <List className={classes.list} xs={4}>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <ListItem className={classes.list_item}>
                    <Phone className={classes.icon}></Phone>
                    <h3 className={classes.icon_name}>Phone</h3>
                    <h4
                      className={classes.description}
                      style={{ color: "#040404", fontSize: "bold" }}
                    >
                      +98(0)9919003830
                    </h4>
                    <h4
                      className={classes.description}
                      style={{ color: "#040404", fontSize: "bold" }}
                    >
                      +98(0)4433653291
                    </h4>
                  </ListItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <ListItem className={classes.list_item}>
                    <Email className={classes.icon}></Email>
                    <h3 className={classes.icon_name}>Email</h3>
                    <h4
                      className={classes.description}
                      style={{ color: "#040404", fontSize: "bold" }}
                    >
                      info@gohartrade.com
                    </h4>
                  </ListItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <ListItem className={classes.list_item}>
                    <Location className={classes.icon}></Location>
                    <h3 className={classes.icon_name}>Address</h3>
                    <h4
                      className={classes.description}
                      style={{ color: "#040404", fontSize: "bold" }}
                    >
                      No. 12, 6th floor, Roma Building, Edalat Ave. Urmia-Iran
                    </h4>
                  </ListItem>
                </GridItem>
              </List>
            </GridContainer>
          </div>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <h3
                    className={classes.title}
                    style={{
                      marginBottom: "0",
                      fontWeight: "bolder",
                      color: "#3c4858",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Person className={classes.icon_person}></Person>
                    Sales & Marketing Manager: (Ms.) Marzieh Razmpisheh
                  </h3>
                </div>
                <hr style={{ margin: "20px auto", opacity: "0.25" }} />
                <div>
                  <List style={{ display: "flex" }}>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <ListItem className={classes.list_item}>
                        <Phone className={classes.icon}></Phone>
                        <h3 className={classes.icon_name}>Phone</h3>
                        <h4
                          className={classes.description}
                          style={{ color: "#040404" }}
                        >
                          +98(0)9125798879
                        </h4>
                      </ListItem>
                    </GridItem>
                  </List>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Getintouch />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <br />
      </div>
      <Footer />
    </div>
  );
}
