import { title } from "assets/jss/material-kit-react.js";
import { costumColor } from "assets/jss/material-kit-react";
import landingPageStyle from "./landingPage";

const contactPageStyle = {
  ...landingPageStyle,
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  list: {
    "@media (min-width: 768px)": {
      display: "flex",
    },
  },

  list_item: {
    display: "inline-block",
    textAlign: "center",
  },

  hr_contact: {
    margin: "20px auto",
    opacity: "0.25",
  },

  icon: {
    color: costumColor,
    fontSize: "xxx-large",
    position: "relative",
    top: "10px",
  },

  icon_name: {
    color: costumColor,
    fontWeight: "bolder",
  },

  icon_person: {
    color: costumColor,
    fontSize: "xxx-large",
    position: "relative",
    top: "10px",
    "@media (max-width: 992px)": {
      display: "block",
      width: "100%",
    },
  },

  title_contact: {
    color: "#3c4858",
    fontWeight: "300px",
  },

  info: {
    color: "#060606",
  },

  formInput: {
    border: "1px solid #ccc",
    padding: "16px 8px",
    display: "block",
    width: "50%",
    overflow: "visible",
    font: "inherit",
    margin: "auto",
    "&:focus": {
      outline: "none",
      border: "2px solid #000", 
    },
},

};

export default contactPageStyle;
