import React from "react";
import { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Download from "@material-ui/icons/ArrowDownward";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import Navbar from "views/Header/Navbar.js";
import SocialLinks from "views/Header/SocialLinks.js";
import SnackbarContentView from "views/SnackbarContentView/SnackbarContentView";

import axios from "axios";

import styles from "assets/jss/material-kit-react/views/downloadPage.js";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

function CardSection(category){
  const classes = useStyles();
  const [documentItems, setDocumentItems] = useState([]);
  const callDocumentAPI = async (category) => {
      await axios
        .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getAllDocumentItems", {category}, {})
        .then((response) => {
          setDocumentItems(response.data);
        })
        .catch((error) => console.log("error in getting product" + error));
    }

    useEffect(() => {
      callDocumentAPI(category);
      
    }, []);

    function renderDocumentCard(){
      return documentItems.map((item, index) => {
        const {Document_title, Document_subtitle, Document_filename} = item;
        var shortTitle = "" + Document_title;
        var shortSubtitle = "" + Document_subtitle;
        shortSubtitle = shortSubtitle.slice(0, 100);

        const handleClick = () => {
          console.log("download started...")
          // using Java Script method to get PDF file
          fetch(process.env.REACT_APP_API_ADDRESS + "/documents/" + Document_filename).then((response) => {
              response.blob().then((blob) => {
                  console.log("blob received......")
                  // Creating new object of PDF file
                  const fileURL =
                      window.URL.createObjectURL(blob);   
                  // Setting various property values
                  let alink = document.createElement("a");
                  alink.href = fileURL;
                  alink.download = "SamplePDF.pdf";
                  alink.click();
              });
          });
      };
      return (
        <GridItem xs={12} sm={12} md={6} lg={6} key={index} sx={{ flex: 1, display: 'flex', flexDirection: 'column'}} className={classes.card}>
            <Card sx={{ display: 'flex', height: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{width: "60%"}}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" style={{color: "#3C4858"}}>
                        {shortTitle}
                    </Typography>
                    <Typography variant="subtitle1" component="div" style={{color: "#3C4858"}}>
                        {shortSubtitle}
                    </Typography>
                    </CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        <a href={process.env.REACT_APP_API_ADDRESS + "/documents/" + Document_filename} target = "_blank" rel="noreferrer">
                            <Button 
                                color="costum"
                                style={{marginLeft: "5px", alignItems: "end"}}
                                onClick={handleClick}
                            >
                                Download
                                <Download style={{marginLeft: "1px"}}/>
                            </Button> 
                        </a>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{margin: "10px"}}>
                    <CardMedia
                        component="img"
                        sx={{ width: 150, height: 150 }}
                        image={process.env.REACT_APP_API_ADDRESS + "/documents/" + Document_filename}
                        alt={Document_title}
                        style={{marginBottom: "5px"}}
                    />
                </Box>
            </Card>
        </GridItem>
      );
      })
    }

  return(
      <>
          <GridContainer className={classes.doubleCards}>
              {renderDocumentCard()}
          </GridContainer>
      </>
  );
}

export default function DownloadPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <head>
        <title>Gohar Tejarat Karimian Documents</title>
        <meta name="description" content="Catalog; Certificate; Article;"></meta>
      </head>
      <SnackbarContentView/>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<Navbar />}
        rightLinks={<SocialLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
        style={{ paddingTop: "50px" }}
      />
      <Parallax
        filter
        image={require("assets/img/downloads/download.jpg").default}
      >
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div style={{textAlign: "center"}}>
            <h2 className={classes.title}>Related Documents</h2>
            <hr style={{ margin: "20px auto", opacity: "0.25" }} />
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
              <NavPills
                color="costum"
                alignCenter
                tabs={[
                  {
                    tabButton: "Catalog",
                    tabContent: (
                      <GridContainer className={classes.tab_content}>
                        <GridItem lg={12} md={12}>
                          {CardSection("Catalog")}
                        </GridItem>
                      </GridContainer>                        
                    ),
                  },
                  {
                    tabButton: "Certificate",
                    tabContent: (
                      <GridContainer className={classes.tab_content}>
                        <GridItem lg={12} md={12}>
                          {CardSection("Certificate")}
                        </GridItem>
                      </GridContainer>                        
                    ),
                  },
                  {
                    tabButton: "Article",
                    tabContent: (
                      <GridContainer className={classes.tab_content}>
                        <GridItem lg={12} md={12}>
                          {CardSection("Article")}
                        </GridItem>
                      </GridContainer>                        
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
        <br />
      </div>
      <Footer />
    </div>
  );
}
