import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PhotoGallery from "components/PhotoGallery/PhotoGallery";

import styles from "assets/jss/material-kit-react/views/productSubFeaturedStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSubFeaturedSection(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <PhotoGallery product={props.product} masterProduct={props.masterProduct}/>
        </GridItem>
      </GridContainer>
    </div>
  );
}
