const productCollectionPageStyle = {
  font_color: {
    color: "#060606",
  },

  description_align: {
    textAlign: "",
    "@media (min-width: 992px)": {
      textAlign: "justify",
    },
  },

  card_img: {
    "@media (min-width: 992px)": {
      width: "500px",
      maxHeight: "200px",
      objectFit: "cover",
    },
  },

  icon_button: {
    color: "#3c4858",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)!important",
      color: "#d3b574",
      textShadow: "0px 0.5px",
    },
  },
};

export default productCollectionPageStyle;
