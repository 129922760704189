export const menuItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About Us",
    url: "/about",
  },
  {
    title: "Product",
  },
  {
    title: "Services",
    submenu: [
      {
        title: "Import & Export",
        url: "/services",
        index: 0,

      },
      {
        title: "Customs Brokerage Services",
        url: "/services",
        index: 1,
      },
      {
        title: "Freight Services",
        url: "/services",
        index: 2,
      },
      {
        title: "Outsourcing Marketing Services",
        url: "/services",
        index: 3,
      },
    ],
  },
  {
    title: "Contact Us",
    url: "/contact",
  },
  {
    title: "More",
    submenu: [
      {
        title: "News",
        url: "/news",
      },
      {
        title: "Events",
        url: "/events",
      },
      {
        title: "Downloads",
        url: "/downloads",
      },
    ],
  },
];
