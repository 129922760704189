import { cardTitle } from "assets/jss/material-kit-react.js";

const photoGalleryStyle = {
  cardTitle,
  smallTitle: {
    color: "#6c757d",
  },
  description: {
    color: "#999",
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  cardResp: {
    marginTop: "0px", 
    paddingLeft: "10%", 
    "@media (max-width: 576px)": {
      marginTop: "50px", 
      paddingLeft: "0",
    },
  },
};

export default photoGalleryStyle;
