import React from "react";
import { MapContainer, TileLayer } from 'react-leaflet';
//import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
import '../../assets/css/map.css';
// import marker from '../../assets/img/marker-icon.png';

export default function Map() {
    // const icon = L.icon({ iconUrl: marker });
    return (
        <div style={{height: "100%"}}>
            <MapContainer center={[37.5499978, 45.05]} zoom={12} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://carto.com/">carto.com</a> contributors'
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
                    noWrap="True"
                />
                {/* <Marker position={[37.522411, 45.05]} icon={icon}> */}
                {/* </Marker> */}
            </MapContainer>
        </div>        
    );
}
