import React from "react";
import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import Navbar from "views/Header/Navbar.js";
import SocialLinks from "views/Header/SocialLinks.js";
import SnackbarContentView from "views/SnackbarContentView/SnackbarContentView.js";

import styles from "assets/jss/material-kit-react/views/contactPage.js";

import axios from "axios";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

export default function About(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [content, setContent] = useState('');
  const callAPI = async () => {
    await axios
      .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getSettings", {}, {})
      .then((response) => {
        setContent(response.data[0].Settings_aboutus);
      })
      .catch((error) => console.log("error in getting setting data" + error));
  }

  useEffect(() => {
    callAPI();
  }, []);

  return (
    <div>
      <head>
        <title>About Gohar Tejarat Karimian</title>
        <meta name="description" content="Like any other organization, we strive to provide goods and services with high quality and completive price as fast as possible; but what makes us different is being active in two countries of Iran and UAE. The former has plenty of raw materials and products demanded by other countries, the latter is a bridge to conquer any customs and import limitations or any potential restrictions in both origin and destination countries. Beside further options to shorten transport distance, we are capable of supplying private brand packaging."></meta>
      </head>
      <SnackbarContentView />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<Navbar />}
        rightLinks={<SocialLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
        style={{ paddingTop: "50px" }}
      />
      <Parallax
        filter
        image={require("assets/img/about/about4.jpg").default}
      >
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h2
                className={classNames(classes.title, classes.title_contact)}
                style={{
                  marginBottom: "0",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                About
                <span style={{ color: "#d3b574" }}>
                  <span> </span>Gohar Tejarat Karimian
                </span>
              </h2>
              <hr className={classes.hr_contact} />
              <div className={classes.info} style={{ textAlign: "justify" }}>
                <br />
                <h4 style={{whiteSpace: "pre-wrap"}}>{content}</h4>
                <br />
                <h4>
                  Thank you for your interest in our products.
                </h4>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <br />
      </div>
      <Footer />
    </div>
  );
}
