const customModalStyle = {
  modalBackground: {
    opacity: "1",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "1043",
    position: "fixed",
    outline: "0",
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  modalContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    textAlign: "center",
  },
  inputRootCustomClasses: {
    margin: "0!important",
  },
  formControl: {
    margin: "0 !important",
    paddingTop: "0",
  },
  searchIcon: {
    width: "20px",
    height: "20px",
    color: "inherit",
  },
};

export default customModalStyle;
