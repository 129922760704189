import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";
import "./assets/css/navbar.css";

import HomePage from "views/HomePage/HomePage.js";
import ProductDetailPage from "views/ProductDetailPage/ProductDetailPage.js";
import ContactPage from "views/ContactPage/ContactPage";
import About from "views/About/About";
import NewsPage from "views/NewsPage/NewsPage";
import ServicePage from "views/ServicePages/ServicePage";
import Catalog from "views/Catalogs/Catalog/Catalog";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import EventsPage from "views/EventsPage/EventsPage";
import DownloadsPage from "views/DownloadsPage/DownloadsPage";
import BlogPage from "views/BlogPage/BlogPage";
// import ImpExp from "views/ServicePages/ImpExp";
// import Brokerage from "views/ServicePages/Brokerage";
// import Freight from "views/ServicePages/Freight";
// import Outsourcing from "views/ServicePages/Outsourcing";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <ScrollToTop />
    <Switch>
      <Route path="/home-page" component={HomePage} />
      <Route path="/services" component={ServicePage} />
      {/* <Route path="/imp-exp" component={ImpExp} />
      <Route path="/brokerage" component={Brokerage} />
      <Route path="/freight" component={Freight} />
      <Route path="/outsourcing" component={Outsourcing} /> */}
      <Route path="/catalog/:id" component={Catalog} />
      <Route path="/product/:id" component={ProductDetailPage} />
      <Route path="/news" component={NewsPage} />
      <Route path="/events" component={EventsPage} />
      <Route path="/downloads" component={DownloadsPage} />
      <Route path="/blog/:id" component={BlogPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/about" component={About} />
      <Route path="/" component={HomePage} />
    </Switch>
  </Router>,
  document.getElementById("root")

);
