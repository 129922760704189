import React, { useState } from "react";
import { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import Navbar from "views/Header/Navbar.js";
import SocialLinks from "views/Header/SocialLinks.js";
import SnackbarContentView from "views/SnackbarContentView/SnackbarContentView.js";

import ProductFeaturedSection from "./Sections/ProductFeaturedSection.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

import axios from "axios";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Catalog(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const category_id = props.match.params.id;
  const [category, setCategory] = useState([{}]);
  const [active, setActive] = useState(props.location.index);
  const [product, setProduct] = useState([{}]);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [image, setImage] = useState();
  function generateTab(){
    var list = [];
    category.map((item) => {
      var productList = [];
      if(item.Category_parentId != 0)
      {
        product.map((p) => {
          if(p.Product_parentId == item.Category_id){
            productList.push(p);
          }
        })
        var title = item.Category_title;
        list.push(
          {
            tabButton: title,
            tabContent: <ProductFeaturedSection product={productList}/>,
          },
        )
      }
    })
    return list;    
  }
  const callAPIProduct = async (id) => {
    await axios
      .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getProducts", { id }, {})
      .then((response) => {
        setProduct(response.data);
      })
      .catch((error) => console.log("error in getting product" + error));
  }
  const callAPI = async (id) => {
    await axios
      .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getCategoryDetail", { id }, {})
      .then((response) => {
        response.data.map((item) => {
          if(item.Category_parentId == 0)
          {
            callAPIProduct(item.Category_id);
            setTitle(item.Category_title);
            setDescription(item.Category_description);
            setImage(item.Category_filename);
          }
        })
        setCategory(response.data);
      })
      .catch((error) => console.log("error in getting category detail" + error));
  }

  useEffect(() => {
    callAPI(props.match.params.id);
  }, [category_id]);

  return (
    <div>
      <head>
        <title>Products</title>
        <meta name="description" content="Foods and Beverages, Herbs, Liquorice, Black tea, White tea, Green tea, CTC tea, Spice, Red pepper, Cumin, Agricultural Machinery, Combine, Crops, Summer products, Sweet pepper, Watermelon, Crops, Potato, Corn, Garden products, Grape, Apple"></meta>
      </head>
      <SnackbarContentView />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<Navbar />}
        rightLinks={<SocialLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
        style={{ paddingTop: "50px" }}
      />
      <Parallax small filter image={process.env.REACT_APP_API_ADDRESS + "/categories/" + image} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6} style={{ marginTop: "80px" }}>
                <div className={classes.profile}>
                  <div className={classes.name}>
                    <h3 className={classes.title}>{title}</h3>
                    <hr style={{ margin: "20px auto", opacity: "0.25" }} />
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p> {description} </p>
            </div>
            <GridContainer justify="center" style={{ marginTop: "100px" }}>
              <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
                <NavPills
                  setActive = {setActive}
                  active = {active}
                  alignCenter
                  color="costum"
                  tabs={generateTab()}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <br />
      </div>
      <Footer />
    </div>
  );
}
