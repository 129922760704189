import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader";

import ActiveThumbnail from "./ActiveThumbnail.js";
import ThumbnailGrid from "./ThumbnailGrid.js";

import styles from "assets/jss/material-kit-react/components/photoGalleryStyle.js";
import { useState } from "react";

const useStyles = makeStyles(styles);

export default function PhotoGallery(props) {
  const [activeImage, setActiveImage] = useState();
  const classes = useStyles();
  
  useEffect(() => {
    setActiveImage(props.masterProduct.Product_filename)
  }, [props])

  return (
    <div
      style={{
        margin: "40px auto",
        background: "inherit!important",
        display: "flex",
        textAlign: "left",
      }}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <div style={{ float: "left", width: "100%" }}>
          <ActiveThumbnail image={activeImage}/>
          <ThumbnailGrid handleClick={setActiveImage} product={props.product} masterProduct={props.masterProduct}/>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <div style={{ float: "left", width: "100%" }}>
            <Card plain className="cardResp">
              <CardHeader style={{ textAlign: "center" }}>
                <div>
                  <h3 className={classes.cardTitle}>{props.masterProduct.Product_title}</h3>
                </div>
                <span>
                  <h5
                    className={classes.smallTitle}
                    style={{ fontWeight: "500", color: "#7a7a7a" }}
                  >
                    Available
                  </h5>
                </span>
              </CardHeader>
              <CardBody>
                <hr style={{ margin: "20px auto", opacity: "0.25" }} />
                <div style={{whiteSpace: "pre-wrap", textAlign: "justify", color: "#060606"}}>
                  {props.masterProduct.Product_description}
                </div>
              </CardBody>
              <CardFooter className={classes.justifyCenter}></CardFooter>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
