import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import BasicCard from "components/Card/Card.js";
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';

import Navbar from "views/Header/Navbar.js";
import SocialLinks from "views/Header/SocialLinks.js";
import SnackbarContentView from "views/SnackbarContentView/SnackbarContentView";

import axios from "axios";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

function CardSection(){
  const classes = useStyles();
  const history = useHistory();
  const [eventItems, setEventItems] = useState([]);
  function handleClick(target, event) {
    history.push({
      pathname:target,
      state:{
        event:event
      }
    });
  }
  
  const callTopEventAPI = async () => {
      await axios
        .post(process.env.REACT_APP_API_ADDRESS + "/api/Homepage/getAllEventItems", {}, {})
        .then((response) => {
          setEventItems(response.data);
        })
        .catch((error) => console.log("error in getting product" + error));
    }

    useEffect(() => {
      callTopEventAPI();
      
    }, []);

  function renderCard(){
      return eventItems.map((item, index) => {
          const {Event_id, Event_title, Event_date, Event_description} = item;
          var shortDescription = "" + Event_description;
          shortDescription = shortDescription.slice(0, 120);
            return (
              <GridItem xs={12} sm={12} md={6} lg={4} key={index} sx={{ flex: 1, display: 'flex', flexDirection: 'column'}} className={classes.card}>
                  <BasicCard style={{justifyContent: "center", height: '100%' }}>
                      <CardHeader style={{textAlign: "center", marginTop: "5px", color: "#3C4858", fontWeight: "bolder"}}>
                          <h3>{Event_title}</h3>
                          <h5>{Event_date}</h5>
                      </CardHeader>
                      <CardBody>
                          <p style={{textAlign: "justify", color: "rgb(4, 4, 4)", whiteSpace: "pre-wrap"}}>
                              {shortDescription}
                          </p>
                      </CardBody>
                      <CardFooter style={{justifyContent: "center"}}>
                          <Button
                              size="small"
                              color="costum"
                              onClick={() => handleClick("/blog/"+Event_id, item)}
                          >
                              Find out More
                          </Button>
                      </CardFooter>
                  </BasicCard>
              </GridItem>
            );
        })
      }
  
      return(
          <>
              <GridContainer className={classes.tripleCards}>
                  {renderCard()}
              </GridContainer>
          </>
      );

}

export default function EventsPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <head>
        <title>About Gohar Tejarat Karimian</title>
        <meta name="description" content="Gohar Tejarat Karimian's Blogs, contents, news"></meta>
      </head>
      <SnackbarContentView />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<Navbar />}
        rightLinks={<SocialLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
        style={{ paddingTop: "50px" }}
      />
      <Parallax
        filter
        image={require("assets/img/events/event.jpg").default}
      >
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div style={{textAlign: "center"}}>
            <h2 className={classes.title} style={{color: "#3C4858"}}>Events</h2>
            <hr style={{ margin: "20px auto", opacity: "0.25" }} />
          </div>
          <CardSection />
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
}
