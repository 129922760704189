import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import CardHeader from "@mui/material/CardHeader";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import myStyles from "assets/jss/material-kit-react/views/productCollectionPage";

const useStyles = makeStyles(styles);
const useMyStyles = makeStyles(myStyles);

export default function ProductFeaturedSection(props) {
  const {product, ...rest} = props;
  var _rest = rest
  const classes = useStyles();
  const myClasses = useMyStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgFluid,
    myClasses.card_img
  );
  const history = useHistory();
  function handleClick(target, product) {
    history.push({
      pathname:target,
      state:{
        product:product
      }
    });
  }
  function renderProductCard(){
    return product.map((item, index) => {
      const {Product_description, Product_title} = item;
      var shortDescription = ""+Product_description;
      shortDescription = shortDescription.slice(0, 200);
      return(
        <GridItem xs={12} sm={6} md={4} key={index} style={{marginTop: "8px"}}>
            <Card style={{height: '100%'}}>
              <CardHeader />
              <GridItem
                xs={12}
                sm={6}
                md={6}
                className={classes.itemGrid}
                style={{ maxWidth: "90%" }}
              >
                <img src={process.env.REACT_APP_API_ADDRESS + "/products/"+item.Product_filename} alt="..." className={imageClasses} />
              </GridItem>
              <h4
                className={classNames(classes.cardTitle, myClasses.font_color)}
              >
                {Product_title}
                <br />
              </h4>
              <CardBody>
                <p
                  className={classNames(
                    classes.description,
                    myClasses.font_color,
                    myClasses.description_align
                  )}
                >
                  {shortDescription}
                  <MoreHorizOutlinedIcon style={{ verticalAlign: "middle" }} />
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <IconButton
                  size="small"
                  aria-label="see more"
                  onClick={() => handleClick("/product/"+item.Product_id, item)}
                  className={myClasses.icon_button}
                >
                  see more
                </IconButton>
              </CardFooter>
            </Card>
          </GridItem>
      )
    })
  }
  
  return (
    <div className={classes.section} style={{ padding: "0px" }}>
      <div>
        <GridContainer>
          {renderProductCard()}
        </GridContainer>
      </div>
    </div>
  );
}
